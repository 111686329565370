import React, { useState } from "react";

const MyAccount: React.FC = () => {
  return (
    <div>
      <h2>My Account</h2>
    </div>
  );
};

export default MyAccount;
