import React from "react";

const UsersEdit: React.FC = () => {
  return (
    <div>
      <h2><span className="gray-out">Users /</span> Edit</h2>
    </div>
  );
};

export default UsersEdit;