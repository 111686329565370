import React, { useState } from "react";
import "./JobsPool.css";
import { TbEdit } from "react-icons/tb";
import { MdDeleteForever } from "react-icons/md";
import { factoryObject } from "../../factoryObject";
import { poolObject } from "../../poolObject";

const JobsPool: React.FC = () => {
  console.log("factoryObject", factoryObject);
  
  return (
    <div>
      <h2>Jobs Pool</h2>

      <div className="jobs-pool-container">
        <div className="jobs-pool-equipment">
          <p>Factory Equipment</p>
          {factoryObject.map((equipment) => (
            <div key={equipment.id} className="equipment-block">
              <div className="jobs-pool-equipment-img"><img src={equipment.image} alt="Nailer 1" /></div>
              <div className="jobs-pool-equipment-block-one">
                <p><span className="bold">Equipment name:</span> {equipment.equipmentName}</p>
                <p><span className="bold">Current Job:</span> {equipment.currentJob}</p>
                <p><span className="bold">Current Panel:</span> {equipment.currentPanel}</p>
              </div>
              <div className="jobs-pool-equipment-block-two">
                <p><span className="bold">Status:</span> {equipment.status}</p>
                <p><span className="bold">Job Progress:</span> {equipment.progressJob}%</p>
                <p><span className="bold">Panel Progress:</span> {equipment.progressPanel}</p>
              </div>
            </div>
            )
          )}
        </div>

        <div className="jobs-pool-jobs">
          <div className="pool-status">
            <p>Factory Pool</p>
            <div className="all-jobs">
              <p>
                All jobs: <span>23</span>
              </p>
              <p>
                Compleated Jobs: <span>19</span>
              </p>
            </div>
            <div className="load-jobs">
              <p>Load Jobs</p>
            </div>
          </div>

          {poolObject.map((poolJobs) => (
            <div key={poolJobs.id} className="current-job">
              <div className="current-job-info">
                <p>Job Name: {poolJobs.jobName}</p>
                <p>Status: {poolJobs.status}</p>
                <p>Progress: {poolJobs.overallProgress}%</p>
                <p>{poolJobs.equipmentName}</p>
              </div>
              <div className="edit-block">
                <TbEdit className="edit-block-edit" size={20} />
                <MdDeleteForever className="edit-block-delete" size={22} />
              </div>
            </div>
            )
          )}  
        </div>
      </div>
    </div>
  );
};

export default JobsPool;
