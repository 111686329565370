import React from "react";
import homePageVideo from "../videos/welcome-video3.mp4";

interface HomePageProps {
  onSignInClick: () => void;
}

const HomePage: React.FC<HomePageProps> = ({ onSignInClick }) => {
  return (
    <div className="home-page">
      <video autoPlay muted loop className="video-background">
        <source src={homePageVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="home-page__content">
        <h2>Welcome to AdMachTec Portal</h2>
        <p>Unleash your production potential!</p>
        <button className="sign-in-button" onClick={onSignInClick}>
          Log In
        </button>
      </div>
    </div>
  );
};

export default HomePage;
