import React, { useState } from "react";
import { FaSearch } from "react-icons/fa";

interface SearchBarProps {
  onSearch: (query: string) => void;
  isSidebarFixed: boolean;
}

const SearchBar: React.FC<SearchBarProps> = ({ onSearch, isSidebarFixed }) => {
  const [searchQuery, setSearchQuery] = useState<string>("");

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const handleButtonClick = () => {
    onSearch(searchQuery);
  };

  return (
    <div
      className={`search-bar-container ${
        isSidebarFixed ? "increased-margin" : ""
      }`}
    >
      <input
        type="text"
        placeholder="Search..."
        value={searchQuery}
        onChange={handleInputChange}
        className="search-input"
      />
      <button className="search-button" onClick={handleButtonClick}>
        <i className="feather icon-search">
          <FaSearch size={24} />
        </i>
      </button>
    </div>
  );
};

export default SearchBar;
