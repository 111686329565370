import React from "react";

const UsersList: React.FC = () => {
  return (
    <div>
      <h2><span className="gray-out">Users /</span> List</h2>
    </div>
  );
};

export default UsersList;