import React from "react";
import Dashboard from "./SidebarComponents/Dashboard";
import DashboardLifeView from "./SidebarComponents/DashboardLifeView";
import MyAccount from "./SidebarComponents/MyAccount";
import MyAccountProfile from "./SidebarComponents/MyAccountProfile";
import MyAccountNotificationPreferences from "./SidebarComponents/MyAccountNotificationPreferences";
import Equipment from "./SidebarComponents/Equipment";
import EquipmentStatus from "./SidebarComponents/EquipmentStatus";
import EquipmentEvents from "./SidebarComponents/EquipmentEvents";
import EquipmentJobs from "./SidebarComponents/EquipmentJobs";
import EquipmentSettings from "./SidebarComponents/EquipmentSettings";
import Analytics from "./SidebarComponents/Analytics";
import AnalyticsCharts from "./SidebarComponents/AnalyticsCharts";
import AnalyticsCompare from "./SidebarComponents/AnalyticsCompare";
import JobsPool from "./SidebarComponents/JobsPool";
import JobsPoolPreview from "./SidebarComponents/JobsPoolPreview";
import JobsPoolEdit from "./SidebarComponents/JobsPoolEdit";
import Users from "./SidebarComponents/Users";
import UsersList from "./SidebarComponents/UsersList";
import UsersEdit from "./SidebarComponents/UsersEdit";
import Companies from "./SidebarComponents/Companies";
import CompaniesList from "./SidebarComponents/CompaniesList";
import CompaniesEdit from "./SidebarComponents/CompaniesEdit";
import Factories from "./SidebarComponents/Factories";
import FactoriesList from "./SidebarComponents/FactoriesList";
import FactoriesEdit from "./SidebarComponents/FactoriesEdit";
import Groups from "./SidebarComponents/Groups";
import GroupsList from "./SidebarComponents/GroupsList";
import GroupsEdit from "./SidebarComponents/GroupsEdit";
import EquipmentEdit from "./SidebarComponents/EquipmentEdit";

interface ContentComponentProps {
  title: string;
  className: string;
  isSidebarFixed: boolean;
}

const ContentComponent: React.FC<ContentComponentProps> = ({
  title,
  className,
  isSidebarFixed,
}) => {
  let content: React.ReactNode;

  switch (title) {
    case "Dashboard":
      content = <Dashboard />;
      break;
    case "DashboardLifeView":
      content = <DashboardLifeView />;
      break;
    case "MyAccount":
      content = <MyAccount />;
      break;
    case "MyAccountProfile":
      content = <MyAccountProfile />;
      break;
    case "MyAccountNotificationPreferences":
      content = <MyAccountNotificationPreferences />;
      break;
    case "Equipment":
      content = <Equipment />;
      break;
    case "EquipmentStatus":
      content = <EquipmentStatus />;
      break;
    case "EquipmentEvents":
      content = <EquipmentEvents />;
      break;
    case "EquipmentJobs":
      content = <EquipmentJobs />;
      break;
    case "EquipmentSettings":
      content = <EquipmentSettings />;
      break;
    case "EquipmentEdit":
      content = <EquipmentEdit />;
      break;
    case "Analytics":
      content = <Analytics />;
      break;
    case "AnalyticsCharts":
      content = <AnalyticsCharts />;
      break;
    case "AnalyticsCompare":
      content = <AnalyticsCompare />;
      break;
    case "JobsPool":
      content = <JobsPool />;
      break;
    case "JobsPoolPreview":
      content = <JobsPoolPreview />;
      break;
    case "JobsPoolEdit":
      content = <JobsPoolEdit />;
      break;
    case "Users":
      content = <Users />;
      break;
    case "UsersList":
      content = <UsersList />;
      break;
    case "UsersEdit":
      content = <UsersEdit />;
      break;
    case "Companies":
      content = <Companies />;
      break;
    case "CompaniesList":
      content = <CompaniesList />;
      break;
    case "CompaniesEdit":
      content = <CompaniesEdit />;
      break;
    case "Factories":
      content = <Factories />;
      break;
    case "FactoriesList":
      content = <FactoriesList />;
      break;
    case "FactoriesEdit":
      content = <FactoriesEdit />;
      break;
    case "Groups":
      content = <Groups />;
      break;
    case "GroupsList":
      content = <GroupsList />;
      break;
    case "GroupsEdit":
      content = <GroupsEdit />;
      break;
    default:
      content = <JobsPool />;
  }

  return (
    <div className={`${className} ${isSidebarFixed ? "increased-margin" : ""}`}>
      {content}
    </div>
  );
};

export default ContentComponent;
