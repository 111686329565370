import React from "react";

const EquipmentEvents: React.FC = () => {
  return (
    <div>
      <h2><span className="gray-out">Equipment /</span> Events</h2>
    </div>
  );
};

export default EquipmentEvents;