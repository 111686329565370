import React, { useState } from "react";

const Companies: React.FC = () => {
  return (
    <div>
      <h2>Companies</h2>
    </div>
  );
};

export default Companies;
