import React, { useState } from "react";

const Dashboard: React.FC = () => {
  return (
    <div>
      <h2>Dashboard</h2>
    </div>
  );
};

export default Dashboard;
