import React, { useState, useEffect } from "react";
import "./App.css";
import Sidebar from "./Components/Sidebarnav";
import ContentComponent from "./Components/ContentComponent";
import HomePage from "./Components/HomePage";
import LoginForm from "./Components/LoginForm";
import SearchBar from "./Components/SearchBar";

const App: React.FC = () => {
  const [selectedBlock, setSelectedBlock] = useState<string | null>(null);
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const [loginPageVisible, setLoginPageVisible] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<string | null>("");
  const [isSidebarFixed, setIsSidebarFixed] = useState<boolean>(false);

  const handleBlockClick = (title: string) => {
    setSelectedBlock(title);
  };

  const handleSignInClick = () => {
    setLoginPageVisible(true);
  };

  const handleLoginSuccess = (user: { username: string }) => {
    setLoggedIn(true);
    setSelectedBlock("Home");
    setLoginPageVisible(false);
  };

  const handleLogout = () => {
    setLoggedIn(false);
  };

  const handleSearch = (query: string) => {
    //console.log('Searching for:', query);
    setSearchResults(query);
  };

  return (
    <div className="App">
      {loggedIn ? (
        <>
          <SearchBar onSearch={handleSearch} isSidebarFixed={isSidebarFixed} />
          <Sidebar
            onBlockClick={handleBlockClick}
            onLogoutClick={handleLogout}
            setIsSidebarFixed={setIsSidebarFixed}
          />
          <div className={`content`}>
            {selectedBlock ? (
              <ContentComponent
                className={`content-component ${
                  isSidebarFixed ? "increased-margin" : ""
                }`}
                title={selectedBlock}
                isSidebarFixed={isSidebarFixed}
              />
            ) : (
              <HomePage onSignInClick={handleSignInClick} />
            )}
          </div>
        </>
      ) : loginPageVisible ? (
        <LoginForm onLogin={handleLoginSuccess} />
      ) : (
        <HomePage onSignInClick={handleSignInClick} />
      )}
    </div>
  );
};

export default App;
