import React from "react";

const JobsPoolPreview: React.FC = () => {
  return (
    <div>
      <h2><span className="gray-out">Jobs Pool /</span> Preview</h2>
    </div>
  );
};

export default JobsPoolPreview;