import React from "react";

const GroupsEdit: React.FC = () => {
  return (
    <div>
      <h2><span className="gray-out">Groups /</span> Edit</h2>
    </div>
  );
};

export default GroupsEdit;