import React from 'react';

interface LogoutProps {
  onLogoutClick: () => void;
}

const Logout: React.FC<LogoutProps> = ( {onLogoutClick} ) => {

  return (
    <div className="logout-container">
      <button onClick={onLogoutClick}>Logout</button>
    </div>
  );
};

export default Logout;
