import React, { useState } from "react";
import whiteLogo from "../images/White-logo-img.png";
import { FaRegEnvelopeOpen } from "react-icons/fa";
import { RiAccountCircleLine } from "react-icons/ri";
import { GrVirtualMachine } from "react-icons/gr";
import { IoAnalyticsSharp } from "react-icons/io5";
import { GiPoolTriangle } from "react-icons/gi";
import { LuUsers } from "react-icons/lu";
import { BsBuildings } from "react-icons/bs";
import { LiaLayerGroupSolid } from "react-icons/lia";
import { ImOffice } from "react-icons/im";
import { IoIosArrowDown } from "react-icons/io";
import { MdKeyboardArrowRight } from "react-icons/md";
import { FaRegCircle, FaRegDotCircle } from "react-icons/fa";
import Logout from "./Logout";

interface SidebarProps {
  onBlockClick: (title: string) => void;
  onLogoutClick: () => void;
  setIsSidebarFixed: (fixed: boolean) => void;
}

const Sidebar: React.FC<SidebarProps> = ({
  onBlockClick,
  onLogoutClick,
  setIsSidebarFixed,
}: SidebarProps) => {
  const [activeIcon, setActiveIcon] = useState<string | null>(null);
  const [circleActive, setCircleActive] = useState(false);
  const [isFixed, setIsFixed] = useState(false);
  const [isDasboardOpen, setIsDasboardOpen] = useState(false);
  const [isMyAccountOpen, setIsMyAccountOpen] = useState(false);
  const [isEquipmentOpen, setIsEquipmentOpen] = useState(false);
  const [isAnalyticsOpen, setIsAnalyticsOpen] = useState(false);
  const [isJobsPoolOpen, setIsJobsPoolOpen] = useState(false);
  const [isUsersOpen, setIsUsersOpen] = useState(false);
  const [isCompaniesOpen, setIsCompaniesOpen] = useState(false);
  const [isFactoriesOpen, setIsFactoriesOpen] = useState(false);
  const [isGroupsOpen, setIsGroupsOpen] = useState(false);

  const handleMouseLeave = () => {
    // hide dropdowv if the Sidebar not fixed
    if (!isFixed) {
      setIsDasboardOpen(false);
      setIsMyAccountOpen(false);
      setIsEquipmentOpen(false);
      setIsAnalyticsOpen(false);
      setIsJobsPoolOpen(false);
      setIsUsersOpen(false);
      setIsCompaniesOpen(false);
      setIsFactoriesOpen(false);
      setIsGroupsOpen(false);
    }
  };

  const handleBlockClick = (title: string) => {
    onBlockClick(title);
    setActiveIcon(title); // Update the active icon on click
  };

  const handleDropdownItemClick = (title: string) => {
    onBlockClick(title); // Handle click event for dropdown items
  };

  const toggleCircle = () => {
    setCircleActive(!circleActive);
    setIsFixed(!isFixed);
    setIsSidebarFixed(!isFixed);
  };

  const toggleLifeView = () => {
    // toggle Dashboard dropdown component
    setIsDasboardOpen(!isDasboardOpen);
  };
  const toggleMyAccount = () => {
    // toggle My Account dropdown component
    setIsMyAccountOpen(!isMyAccountOpen);
  };
  const toggleEquipment = () => {
    // toggle Equipment dropdown component
    setIsEquipmentOpen(!isEquipmentOpen);
  };
  const toggleAnalytics = () => {
    // toggle Analytics dropdown component
    setIsAnalyticsOpen(!isAnalyticsOpen);
  };
  const toggleJobsPool = () => {
    // toggle Job Pool dropdown component
    setIsJobsPoolOpen(!isJobsPoolOpen);
  };
  const toggleUsersSidebar = () => {
    // toggle Users dropdown component
    setIsUsersOpen(!isUsersOpen);
  };
  const toggleCompaniesSidebar = () => {
    // toggle Companies dropdown component
    setIsCompaniesOpen(!isCompaniesOpen);
  };
  const toggleFactoriesSidebar = () => {
    // toggle Factories dropdown component
    setIsFactoriesOpen(!isFactoriesOpen);
  };
  const toggleGroupsSidebar = () => {
    // toggle Groups dropdown component
    setIsGroupsOpen(!isGroupsOpen);
  };

  return (
    <div
      className={`sidebar ${isFixed ? "fixed" : ""}`}
      onMouseLeave={handleMouseLeave}
    >
      <div className="logo-container">
        <div className="logoClass">
          <img src={whiteLogo} alt="Admachtec Logo" />
        </div>
        <div className="logo-container-circle" onClick={toggleCircle}>
          {circleActive ? <FaRegDotCircle /> : <FaRegCircle />}
        </div>
      </div>
      <div className="sidebar-icons">
        <div
          className={`sidebar-icon ${
            activeIcon === "Dashboard" ? "active" : ""
          }`}
          onClick={() => handleBlockClick("Dashboard")}
        >
          <div className="block" onClick={toggleLifeView}>
            <div>
              <FaRegEnvelopeOpen size={22} />
            </div>
            <span>Dashboard</span>
            <div className="sidebar-arrow">
              {isDasboardOpen ? <IoIosArrowDown /> : <MdKeyboardArrowRight />}
            </div>
          </div>
        </div>
        {isDasboardOpen && (
          <ul className="dropdown">
            <li onClick={() => handleDropdownItemClick("DashboardLifeView")}>
              Life View
            </li>
          </ul>
        )}

        <div
          className={`sidebar-icon ${
            activeIcon === "MyAccount" ? "active" : ""
          }`}
          onClick={() => handleBlockClick("MyAccount")}
        >
          <div className="block" onClick={toggleMyAccount}>
            <div>
              <RiAccountCircleLine size={24} />
            </div>
            <span>My Account</span>
            <div className="sidebar-arrow">
              {isMyAccountOpen ? <IoIosArrowDown /> : <MdKeyboardArrowRight />}
            </div>
          </div>
        </div>
        {isMyAccountOpen && (
          <ul className="dropdown">
            <li onClick={() => handleDropdownItemClick("MyAccountProfile")}>
              Profile
            </li>
            <li
              onClick={() =>
                handleDropdownItemClick("MyAccountNotificationPreferences")
              }
            >
              Notification Preferences
            </li>
          </ul>
        )}

        <div
          className={`sidebar-icon ${
            activeIcon === "Equipment" ? "active" : ""
          }`}
          onClick={() => handleBlockClick("Equipment")}
        >
          <div className="block" onClick={toggleEquipment}>
            <div>
              <GrVirtualMachine size={24} />
            </div>
            <span>Equipment</span>
            <div className="sidebar-arrow">
              {isEquipmentOpen ? <IoIosArrowDown /> : <MdKeyboardArrowRight />}
            </div>
          </div>
        </div>
        {isEquipmentOpen && (
          <ul className="dropdown">
            <li onClick={() => handleDropdownItemClick("EquipmentStatus")}>
              Status
            </li>
            <li onClick={() => handleDropdownItemClick("EquipmentEvents")}>
              Events
            </li>
            <li onClick={() => handleDropdownItemClick("EquipmentJobs")}>
              Jobs
            </li>
            <li onClick={() => handleDropdownItemClick("EquipmentSettings")}>
              Settings
            </li>
            <li onClick={() => handleDropdownItemClick("EquipmentEdit")}>
              Edit
            </li>
          </ul>
        )}

        <div
          className={`sidebar-icon ${
            activeIcon === "Analytics" ? "active" : ""
          }`}
          onClick={() => handleBlockClick("Analytics")}
        >
          <div className="block" onClick={toggleAnalytics}>
            <div>
              <IoAnalyticsSharp size={24} />
            </div>
            <span>Analytics</span>
            <div className="sidebar-arrow">
              {isAnalyticsOpen ? <IoIosArrowDown /> : <MdKeyboardArrowRight />}
            </div>
          </div>
        </div>
        {isAnalyticsOpen && (
          <ul className="dropdown">
            <li onClick={() => handleDropdownItemClick("AnalyticsCharts")}>
              Charts
            </li>
            <li onClick={() => handleDropdownItemClick("AnalyticsCompare")}>
              Compare
            </li>
          </ul>
        )}

        <div
          className={`sidebar-icon ${
            activeIcon === "JobsPool" ? "active" : ""
          }`}
          onClick={() => handleBlockClick("JobsPool")}
        >
          <div className="block" onClick={toggleJobsPool}>
            <div>
              <GiPoolTriangle size={24} />
            </div>
            <span>Jobs Pool</span>
            <div className="sidebar-arrow">
              {isJobsPoolOpen ? <IoIosArrowDown /> : <MdKeyboardArrowRight />}
            </div>
          </div>
        </div>
        {isJobsPoolOpen && (
          <ul className="dropdown">
            <li onClick={() => handleDropdownItemClick("JobsPoolPreview")}>
              Preview
            </li>
            <li onClick={() => handleDropdownItemClick("JobsPoolEdit")}>
              Edit
            </li>
          </ul>
        )}

        <div
          className={`sidebar-icon ${activeIcon === "Users" ? "active" : ""}`}
          onClick={() => handleBlockClick("Users")}
        >
          <div className="block" onClick={toggleUsersSidebar}>
            <div>
              <LuUsers size={24} />
            </div>
            <span>Users</span>
            <div className="sidebar-arrow">
              {isUsersOpen ? <IoIosArrowDown /> : <MdKeyboardArrowRight />}
            </div>
          </div>
        </div>
        {isUsersOpen && (
          <ul className="dropdown">
            <li onClick={() => handleDropdownItemClick("UsersList")}>List</li>
            <li onClick={() => handleDropdownItemClick("UsersEdit")}>Edit</li>
          </ul>
        )}

        <div
          className={`sidebar-icon ${
            activeIcon === "Companies" ? "active" : ""
          }`}
          onClick={() => handleBlockClick("Companies")}
        >
          <div className="block" onClick={toggleCompaniesSidebar}>
            <div>
              <ImOffice size={24} />
            </div>
            <span>Companies</span>
            <div className="sidebar-arrow">
              {isCompaniesOpen ? <IoIosArrowDown /> : <MdKeyboardArrowRight />}
            </div>
          </div>
        </div>
        {isCompaniesOpen && (
          <ul className="dropdown">
            <li onClick={() => handleDropdownItemClick("CompaniesList")}>
              List
            </li>
            <li onClick={() => handleDropdownItemClick("CompaniesEdit")}>
              Edit
            </li>
          </ul>
        )}

        <div
          className={`sidebar-icon ${
            activeIcon === "Factories" ? "active" : ""
          }`}
          onClick={() => handleBlockClick("Factories")}
        >
          <div className="block" onClick={toggleFactoriesSidebar}>
            <div>
              <BsBuildings size={24} />
            </div>
            <span>Factories</span>
            <div className="sidebar-arrow">
              {isFactoriesOpen ? <IoIosArrowDown /> : <MdKeyboardArrowRight />}
            </div>
          </div>
        </div>
        {isFactoriesOpen && (
          <ul className="dropdown">
            <li onClick={() => handleDropdownItemClick("FactoriesList")}>
              List
            </li>
            <li onClick={() => handleDropdownItemClick("FactoriesEdit")}>
              Edit
            </li>
          </ul>
        )}

        <div
          className={`sidebar-icon ${activeIcon === "Groups" ? "active" : ""}`}
          onClick={() => handleBlockClick("Groups")}
        >
          <div className="block" onClick={toggleGroupsSidebar}>
            <div>
              <LiaLayerGroupSolid size={26} />
            </div>
            <span>Groups</span>
            <div className="sidebar-arrow">
              {isGroupsOpen ? <IoIosArrowDown /> : <MdKeyboardArrowRight />}
            </div>
          </div>
        </div>
        {isGroupsOpen && (
          <ul className="dropdown">
            <li onClick={() => handleDropdownItemClick("GroupsList")}>List</li>
            <li onClick={() => handleDropdownItemClick("GroupsEdit")}>Edit</li>
          </ul>
        )}
      </div>
      <Logout onLogoutClick={onLogoutClick} />
    </div>
  );
};

export default Sidebar;
