import React from "react";

const AnalyticsCompare: React.FC = () => {
  return (
    <div>
      <h2><span className="gray-out">Analytics /</span> Compare</h2>
    </div>
  );
};

export default AnalyticsCompare;