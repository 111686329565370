import React, { useState } from "react";

const Users: React.FC = () => {
  return (
    <div>
      <h2>Users</h2>
    </div>
  );
};

export default Users;
