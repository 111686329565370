import React from "react";

const EquipmentSettings: React.FC = () => {
  return (
    <div>
      <h2><span className="gray-out">Equipment /</span> Settings</h2>
    </div>
  );
};

export default EquipmentSettings;