import React from "react";

const AnalyticsCharts: React.FC = () => {
  return (
    <div>
      <h2><span className="gray-out">Analytics /</span> Charts</h2>
    </div>
  );
};

export default AnalyticsCharts;