import React, { useState, useEffect } from "react";
//import { fakeUserData } from "../fakeData";
import DOMPurify from "dompurify";

interface LoginFormProps {
  onLogin: (user: { username: string }) => void;
  //companyNames: string[]; // Add the prop for company names
}

const LoginForm: React.FC<LoginFormProps> = ({ onLogin }) => {
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  //const [selectedCompany, setSelectedCompany] = useState<string>(""); // State for selected company
  const [error, setError] = useState<string>("");

  const handleLogin = async () => {
    const cleanUsername = DOMPurify.sanitize(username);
    const cleanPassword = DOMPurify.sanitize(password);

    try {
      const response = await fetch("http://127.0.0.1:3004/login" , {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username: cleanUsername, password: cleanPassword })
      });

      console.log('HERE', username, password);
      
      if (!response.ok) {
        throw new Error("Invalid username or password. Please try again.");
      }

      const user = await response.json();
      onLogin({ username: user.username })
    } catch (error: any) {
      setError(error.message);
      setUsername("");
      setPassword("");
    }
    // Check if the provided username and password match any of the fake user data
    // const user = fakeUserData.find(
    //   (user) => user.username === cleanUsername && user.password === cleanPassword
    // );

    // if (user) {
    //   //console.log('Selected Company ',selectedCompany);
    //   // Simulate a successful login after 1 second
    //   setTimeout(() => {
    //     onLogin({ fName: user.fName });
    //   }, 1000);
    // } else {
    //   setError("Invalid username or password. Please try again.");
    //   // Clear the input fields on error
    //   setUsername("");
    //   setPassword("");
    // }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      handleLogin();
    }
  };

  // const handleCompanyChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
  //   setSelectedCompany(e.target.value);
  // };

  // useEffect(() => {
  //   console.log('Selected Company ', selectedCompany);
  // }, [selectedCompany]);

  return (
    <section>
      {" "}
      <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
      <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
      <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
      <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
      <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
      <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
      <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
      <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
      <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
      <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
      <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
      <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
      <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
      <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
      <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
      <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
      <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
      <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
      <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
      <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
      <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
      <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
      <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
      <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
      <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
      <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
      <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
      <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
      <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
      <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
      <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
      <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
      <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
      <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
      <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
      <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
      <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
      <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
      <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
      <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
      <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
      <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
      <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
      <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
      <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
      <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
      <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
      <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
      <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
      <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
      <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
      <span></span> <span></span> <span></span> <span></span> <span></span>
      <div className="signin">
        <div className="content">
          <h3>AdMachTec Portal</h3>
          <h2>Please Login</h2>
          <div className="form">
            {/* <div className="selectBox">
            <select
                value={selectedCompany}
                onChange={handleCompanyChange}
              >
                <option value="" disabled>Select a company</option>
                {companyNames.map((company, index) => (
                  <option key={index} value={company}>
                    {company}
                  </option>
                ))}
              </select>
            </div> */}
            <div className="inputBox">
              {/* <label>Username:</label> */}
              <input
                type="text"
                autoComplete="off"
                required
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                onKeyDown={handleKeyDown}
              />{" "}
              <i>Username</i>
            </div>

            <div className="inputBox">
              {/* <label>Password:</label> */}
              <input
                type="password"
                autoComplete="off"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onKeyDown={handleKeyDown}
              />{" "}
              <i>Password</i>
            </div>
            <div className="links">
              <a href="tel:+12045586953">Forgot Password</a>
              <a href="tel:+12045586953">Signup</a>
            </div>
            {error && <div className="error">{error}</div>}
            <div className="inputBox">
              {/* <button onClick={handleLogin}>Log In</button> */}
              <input type="submit" onClick={handleLogin} value="Login" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LoginForm;
