import nailer1 from "./images/No_Image_Available.png";
import nailer2 from "./images/No_Image_Available.png";
import nailer3 from "./images/No_Image_Available.png";

export const factoryObject = [
  { id: 1, equipmentName: 'Nailer1', currentJob: 'something llfjgldjfg lkjdflksjfd lkjflkfj', currentPanel: 'abc', status: 'in progress', progressJob: '70', progressPanel: '1/10', image: nailer1 },
  { id: 2, equipmentName: 'Nailer2', currentJob: 'something', currentPanel: 'efg', status: 'paused', progressJob: '30', progressPanel: '2/5', image: nailer2 },
  { id: 3, equipmentName: 'Nailer3', currentJob: 'something', currentPanel: 'high', status: 'in progress', progressJob: '50', progressPanel: '3/8', image: nailer3 },
  { id: 4, equipmentName: 'Nailer4', currentJob: 'something', currentPanel: 'qwerty', status: 'in progress', progressJob: '50', progressPanel: '9/10', image: nailer3 },
  { id: 5, equipmentName: 'Nailer5', currentJob: 'something', currentPanel: 'cvbnmh', status: 'in progress', progressJob: '50', progressPanel: '10/10', image: nailer3 },
];