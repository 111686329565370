import React from "react";

const GroupsList: React.FC = () => {
  return (
    <div>
      <h2><span className="gray-out">Groups /</span> List</h2>
    </div>
  );
};

export default GroupsList;