export const poolObject = [
  { id: 1, equipmentName: 'Nailer1', jobName: 'one',  status: 'in progress',  panels: [
  {panelName: 'panel 1', status: 'in progress', progress: 80, duration: 180}, 
  {panelName: 'panel 2', status: 'paused', progress: 30, duration: 90},
  {panelName: 'panel 3', status: 'in progress', progress: 95, duration: 120}, 
  {panelName: 'panel 4', status: 'done', progress: 32, duration: 60}], overallProgress: '70' },
  { id: 2, equipmentName: 'Nailer2', jobName: 'two', status: 'ready',  panels: [], overallProgress: '30' },
  { id: 3, equipmentName: 'Nailer3', jobName: 'three', status: 'done',  panels: [], overallProgress: '50' },
  { id: 4, equipmentName: 'Nailer4', jobName: 'four', status: 'canceled',  panels: [], overallProgress: '95' },
  { id: 5, equipmentName: 'Nailer5', jobName: 'five', status: 'ready',  panels: [], overallProgress: '70' },
];